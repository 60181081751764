import React from "react";
import Img from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import Form from "../contact/form";
const QueryComp = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          careerInfo: allDataJson(filter: { id: { eq: "career" } }) {
            edges {
              node {
                id
                text
                title1
                title2
                title3
                title4
              }
            }
          }
          siteMeta: allDataJson(filter: { id: { eq: "siteMeta" } }) {
            edges {
              node {
                recaptchaKey
              }
            }
          }
        }
      `}
      render={data => <Career data={data} />}
    />
  );
};

const Career = ({ data }) => {
  let career = data.careerInfo.edges[0].node;
  let recaptchaKey = data.siteMeta.edges[0].node.recaptchaKey;
  const { t } = useTranslation();
  return (
    <>
      <section className="module divider-top bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-9 m-auto">
              <h1><strong>{t(career.title3)}</strong></h1>
              <p className="lead"><h4>{t(career.title1)}</h4></p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 m-auto">
              <p className="lead"><h5>{t(career.text)}</h5></p>
              <p className="lead"><h5>{t(career.title4)}</h5></p>
            </div>
          </div>

          <div className="row pt-5">
            <div className="col-md-9 m-auto">
              <Form recaptchaKey={recaptchaKey} />
              <div
                className="ajax-response text-center"
                id="contact-response"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QueryComp;
