import React from "react";
import withI18next from "../components/utilities/withI18next";
import Layout from "../components/layout/layout";
import Carreer from "../components/career/career";
import Hero from "../components/hero/hero"
import SEO from "../components/seo/seo"

const Page = props => {
  return (
    <>
      <SEO title={props.t("seoCareerTitle")} desc={props.t("seoCareerDescription")} />
      <Layout>
        <Hero image="career" />
        <Carreer />
      </Layout>
    </>
  );
};
export default withI18next({ ns: "common" })(Page);
